<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Fulltime -- Payment Schedule"/>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('add')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-plus-circle</v-icon> Add
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">New Payment Schedule</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field label="Name" v-model="newItem.name" :rules="[validate_rules.required]"> </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                                <v-select
                                v-model="newItem.fulltime_payment_scheme_id"
                                :items="paymentScheme"
                                item-text="name"
                                item-value="id"
                                label="Payment Scheme"
                                single-line
                                :rules="[validate_rules.required]"
                                ></v-select>
                        </v-col>
                    </v-row>
                  <v-row>
                                                      



                    <v-col cols="12" sm="12">

                        <v-menu
                            ref="menu_start_date"
                            v-model="menu.start_date"
                            :close-on-content-click="false"
                            :return-value.sync="newItem.start_date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">

                                <v-text-field
                                    v-model="newItem.start_date"
                                    label="Start Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[validate_rules.required]"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="newItem.start_date"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu.start_date = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu_start_date.save(newItem.start_date)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>

                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">

                        <v-menu
                            ref="menu_end_date"
                            v-model="menu.end_date"
                            :close-on-content-click="false"
                            :return-value.sync="newItem.end_date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="newItem.end_date"
                                    label="End Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[validate_rules.required]"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="newItem.end_date"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu.end_date = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu_end_date.save(newItem.end_date)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>

                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">

                        <v-menu
                            ref="menu_paid_date"
                            v-model="menu.paid_date"
                            :close-on-content-click="false"
                            :return-value.sync="newItem.paid_date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="newItem.paid_date"
                                    label="Paid Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[validate_rules.required]"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="newItem.paid_date"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu.paid_date = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu_paid_date.save(newItem.paid_date)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>

                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('add')" color="blue darken-1" text @click="save">Add</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->

          
        </v-dialog>
        <v-dialog v-model="dialog_edit" max-width="500px">
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">Edit Payment Schedule :: {{ editItem.name }}</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">

                            <v-menu
                                ref="editmenu_start_date"
                                v-model="editMenu.start_date"
                                :close-on-content-click="false"
                                :return-value.sync="editItem.start_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">

                                    <v-text-field
                                        v-model="editItem.start_date"
                                        label="Start Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[validate_rules.required]"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="editItem.start_date"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="editMenu.start_date = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.editmenu_start_date.save(editItem.start_date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">

                            <v-menu
                                ref="editmenu_end_date"
                                v-model="editMenu.end_date"
                                :close-on-content-click="false"
                                :return-value.sync="editItem.end_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editItem.end_date"
                                        label="End Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[validate_rules.required]"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="editItem.end_date"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="editMenu.end_date = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.editmenu_end_date.save(editItem.end_date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">

                            <v-menu
                                ref="editmenu_paid_date"
                                v-model="editMenu.paid_date"
                                :close-on-content-click="false"
                                :return-value.sync="editItem.paid_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editItem.paid_date"
                                        label="Paid Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[validate_rules.required]"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="editItem.paid_date"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="editMenu.paid_date = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.editmenu_paid_date.save(editItem.paid_date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>

                        </v-col>
                    </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="editClose">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('update')" color="blue darken-1" text @click="updateItem()">Edit</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->

          
        </v-dialog>
        <v-dialog v-model="dialog_zeek_schedule"  v-if="selSchedule != null" fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
          <v-card>
                <v-toolbar
                    dark
                    color="primary"
                    >
                    <v-btn
                        icon
                        dark
                        @click="dialog_zeek_schedule = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Manage Zeek Schedule :: {{ selSchedule.name }}, {{ selSchedule.period_start_date }} - {{ selSchedule.period_end_date }}, pay on {{ selSchedule.paid_date }}</v-toolbar-title>
                </v-toolbar>
            <v-card-text>
                <div>

                    <v-row>
                        <v-col cols="6" class="text-center">
                            <h4>Available Zeek Schedule</h4>
                        </v-col>
                        <v-col cols="6" class="text-center">
                            <h4>Attached to this payment</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" class="text-center">
                            <v-text-field
                                v-model="search_available_schedule"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="text-center">
                            <v-text-field
                                v-model="search_attached_schedule"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-data-table
                                dense
                                :headers="header_available_zeek_schedule"
                                :items="available_zeek_schedules"
                                :page="page_available_zeek_schedules"
                                :footer-props="footerPropsZeekPayment"
                                class="elevation-1"
                                :search="search_available_schedule"
                                @pagination="paginateAvailableZeekSchedule"
                                @current-items="computeAvailableSelectAllStatus"
                            >
                            <!--
                            :items-per-page="per_page_available_zeek_schedules"
                            -->
                                <template v-slot:item.free_slot="{ item }">
                                    <v-chip v-for="slot in item.free_slot" label
                                    v-bind:key="slot" small class="ma-1" color="primary" outlined>
                                        {{ slot }}
                                    </v-chip>
                                </template>

                                <template v-slot:item.operation="{ item }">
                                    <v-btn x-small color="primary" v-if="!selSchedule.is_processed" @click="() => {addZeekPayment(item.zeek_schedule_id, item.free_slot)}"> Add </v-btn>
                                </template>

                                <template v-slot:header.operation="{  }">
                                    <v-btn x-small color="primary" v-if="!selSchedule.is_processed" @click="() => {addAllZeekPayment()}" style="height:30px"> Add All <br> Selected</v-btn>
                                </template>

                                <template v-slot:item.sel="{ item }">
                                    <v-checkbox v-if="!selSchedule.is_processed"
                                    :key="item.zeek_schedule_id"
                                    v-model="item.is_sel"
                                    @change="changeSelectAllAvailableToNull"
                                    ></v-checkbox>
                                </template>

                                <template v-slot:header.sel="{ }">
                                    <v-checkbox v-if="!selSchedule.is_processed"
                                    v-model="is_select_all_available"
                                    @change="changeSelectAllAvailable"
                                    :indeterminate="is_select_all_available===null"
                                    ></v-checkbox>
                                </template>

                                <template v-slot:footer.page-text="pageObj">
                                    {{pageObj.pageStart}}-{{pageObj.pageStop}} of {{pageObj.itemsLength}} ( {{ cnt_sel_available_zeek_schedules }} selected )
                                </template>

                            </v-data-table>

                        </v-col>
                        <v-col cols="6" class="text-right">
                            <v-data-table
                                dense
                                :headers="header_attached_zeek_schedule"
                                :items="attached_zeek_schedules"
                                :page="page_attached_zeek_schedules"
                                :items-per-page="10"
                                :footer-props="footerPropsZeekPayment"
                                class="elevation-1"
                                :search="search_attached_schedule"
                                @pagination="paginateAttachedZeekSchedule"
                                @current-items="computeAttachedSelectAllStatus"

                            >
                                <template v-slot:item.operation="{ item }">
                                    <v-btn x-small color="warning" v-if="!selSchedule.is_processed" @click="() => {removeZeekPayment(item.zeek_schedule_id, item.slots)}"> Del </v-btn>
                                </template>

                                <template v-slot:item.slots="{ item }">
                                    <v-chip v-for="slot in item.slots.sort()" label
                                    v-bind:key="slot" small class="ma-1" color="primary" outlined>
                                        {{ slot }}
                                    </v-chip>
                                </template>
                                
                                <template v-slot:header.operation="{  }">
                                    <v-btn x-small color="warning" v-if="!selSchedule.is_processed" @click="() => {removeAllZeekPayment()}" style="height:30px"> Remove All <br> Selected</v-btn>
                                </template>

                                <template v-slot:item.is_sel="{ item }">
                                    <v-checkbox v-if="!selSchedule.is_processed"
                                    :key="item.zeek_schedule_id"
                                    v-model="item.is_sel"
                                    @change="changeSelectAllAttachedToNull"
                                    ></v-checkbox>
                                </template>

                                <template v-slot:header.is_sel="{ }">
                                    <v-checkbox v-if="!selSchedule.is_processed"
                                    v-model="is_select_all_attached"
                                     @change="changeSelectAllAttached"
                                    :indeterminate="is_select_all_attached===null"

                                    ></v-checkbox>
                                </template>

                                <template v-slot:footer.page-text="pageObj">
                                    {{pageObj.pageStart}}-{{pageObj.pageStop}} of {{pageObj.itemsLength}} ( {{ cnt_sel_attached_zeek_schedules }} selected )
                                </template>

                            </v-data-table>
                        </v-col>
                    </v-row>

                </div>
            </v-card-text>

          </v-card>
          <!-- End Modal Content -->

          
        </v-dialog>
      </v-toolbar> 




      <v-divider></v-divider>

    <template>
        <v-dialog v-model="showImportPaymentAdjustmentDialog" v-if="selSchedule && canDo && canDo.can.includes('adjustment_fulltime_payment')" max-width="500px">
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">Import Adjustment :: {{ selSchedule.name }}</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-file-input
                            v-model="importItem.importFile"
                            :rules="[validate_rules.required,validate_rules.filesize,validate_rules.filetype]"
                            accept=".xlsx"
                            placeholder="Browse a xlsx file"
                            label="Payment Report"
                            @change="checkImportFile"
                        ></v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
                <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="importItem.import_file_error != ''" >
                Error :: {{ importItem.import_file_error }} 
                </v-alert>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="() => { showImportPaymentAdjustmentDialog = false }">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('adjustment_fulltime_payment') && importItem.is_import_file_valid === true" color="blue darken-1" text @click="performImportPaymentAdjustment()">Import</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
    </template>

    <template>
        <v-dialog v-model="showReportDialog" v-if="selSchedule != null">
            <v-card>
                <v-card-title>
                <span class="headline">Fulltime Payment on {{ moment(selSchedule.paid_date).format('YYYY-MM-DD') }}, [{{ moment(selSchedule.period_start_date).format('YYYY-MM-DD') }} - {{ moment(selSchedule.period_end_date).format('YYYY-MM-DD') }}]</span>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                            :headers="detail_headers"
                            :items="payment_records"
                            :items-per-page="100"
                            :footer-props="footerProps"
                            show-expand
                            :single-expand="singleExpand"
                            :expanded.sync="expanded"
                            class="elevation-1"
                            >
                                <template v-slot:item.employee_no="{ item }">
                                    <span>{{ item.employee.employee_no }}</span>
                                </template>

                                <template v-slot:item.phone="{ item }">
                                    <span>{{ item.employee.phone }}</span>
                                </template>

                                <template v-slot:item.name_th="{ item }">
                                    <span>{{ item.employee.name_th }}</span>
                                </template>

                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length">
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">
                                                            Date
                                                        </th>
                                                        <th class="text-center">
                                                            Debit
                                                        </th>
                                                        <th class="text-center">
                                                            Credit
                                                        </th>
                                                        <th class="text-center">
                                                            Description
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="detail in item.details" :key="detail.date"  >
                                                        <td class="text-center">{{ detail.date }}</td>
                                                        <td class="text-center">{{ detail.type == "debit" ? detail.amount : "-" }}</td>
                                                        <td class="text-center">{{ detail.type == "credit" ? detail.amount : "-" }}</td>
                                                        <td class="text-center">{{ detail.detail }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </td>
                                </template>
                        </v-data-table>
                </v-card-text>
            </v-card>

        </v-dialog>
    </template>

      <div class="content-wrap">
            <v-row justify="end">
                <v-col cols="12" sm="8" md="6" >
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>

           <v-row>
                
              <v-col cols="12" sm="12" md="12" >
                    <v-data-table
                        :headers="headers"
                        :items="schedules"
                        :items-per-page="100"
                        :footer-props="footerProps"
                        class="elevation-1"
                        :sort-by="['paid_date']"
                        :sort-desc="[true]"
                        :search="search"
                        >

                            <template v-slot:item.period_start_date="{ item }">
                                <span>{{ moment(item.period_start_date).format('YYYY-MM-DD') }}</span>
                            </template>

                            <template v-slot:item.period_end_date="{ item }">
                                <span>{{ moment(item.period_end_date).format('YYYY-MM-DD') }}</span>
                            </template>

                            <template v-slot:item.paid_date="{ item }">
                                <div>{{ moment(item.paid_date).format('YYYY-MM-DD') }}</div>
                            </template>

                            <template v-slot:item.is_processed="{ item }">
                                <div>{{ item.is_processed ? "Yes" : "No" }}</div>
                            </template>

                            <template v-slot:item.zeek_schedule="{ item }">
                                <div>{{ item.zeek_roster_payment.length }}<br>
                                <v-btn x-small @click="() => {showManageZeekRoster(item)}">Manage</v-btn></div>
                            </template>


                            <template v-slot:item.payment_adjustments="{ item }">

                                <div v-if="canDo && (canDo.can.includes('adjustment_fulltime_payment')) && !item.is_processed" class="d-flex flex-column mt-2 mb-2">  
                                    <v-btn x-small @click="() => {clickImportAdjustment(item)}" color="primary">Import</v-btn>
                                    <v-btn x-small @click="() => {clickLoadPaymentForm(item)}">Load Form</v-btn>
                                    <v-btn x-small @click="() => {clickClearAdjustmentAll(item)}" color="warning">Clear All</v-btn>

                                </div>
                            </template>



                            <template v-slot:item.details="{ item }">

                                <v-btn x-small v-if="item.is_processed" @click="() => {showFulltimePaymentReport(item)}">
                                    View
                                </v-btn>

                                <v-btn v-show="canDo && (canDo.can.includes('export_preview_payment') )" x-small color="info" v-else @click="() => {clickExportPreviewPayment(item)}">
                                    Preview
                                </v-btn>

                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-icon v-if="!item.is_processed" v-show="canDo && canDo.can.includes('delete')" @click="deleteItem(item)" >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <template v-slot:item.action_pay="{ item }">
                                <v-icon v-if="!item.is_processed" v-show="canDo && canDo.can.includes('approve')" @click="paidItem(item)" >
                                    mdi-currency-usd
                                </v-icon>
                            </template>

                            <template v-slot:item.action_update="{ item }">
                                <v-icon v-if="!item.is_processed" v-show="canDo && canDo.can.includes('update')" @click="showEditItemModal(item)" >
                                    mdi-wrench
                                </v-icon>
                            </template>

                    </v-data-table>
              </v-col>
          </v-row>
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import lodash from "lodash";

export default {
    name: 'FulltimePaymentSchedule',
    data: () => ( {
        paymentScheme : [],
        expanded: [],
        singleExpand: false,
        dialog : false,
        dialog_edit : false,
        dialog_zeek_schedule : false,
        selSchedule : null,
        showReportDialog : false,
        showImportPaymentAdjustmentDialog : false,
        schedules : [],
        cnt_sel_available_zeek_schedules : 0,
        cnt_sel_attached_zeek_schedules : 0,
        per_page_available_zeek_schedules : 10,
        per_page_attached_zeek_schedules : 10,
        available_zeek_schedules : [],
        attached_zeek_schedules : [],
        page_available_zeek_schedules : 1,
        page_attached_zeek_schedules : 1,
        search : "",
        payment_records : [],
        footerProps: {'items-per-page-options': [50, 100, 500]},
        footerPropsZeekPayment: {'items-per-page-options': [10,100,500]},
        is_select_all_available : false,
        is_select_all_attached : false,
        search_available_schedule : "",
        search_attached_schedule : "",
        header_available_zeek_schedule: [
            { text: 'Select All', value: 'sel', sortable: false, filterable: false },
            { text: 'Zeek Schedule Id', value: 'zeek_schedule_id', sortable: false, filterable: true },
            { text: 'Service Area', value: 'service_area_code', sortable: false, filterable: true },
            { text: 'Region', value: 'region', sortable: false, filterable: true },
            { text: 'Slot', value: 'free_slot', sortable: false, filterable: true },
            { text: 'Add', value: 'operation', sortable: false, filterable: false },
        ],

        header_attached_zeek_schedule: [
            { text: 'Select All', value: 'is_sel', sortable: false, filterable: false },
            { text: 'Remove', value: 'operation', sortable: false, filterable: false },
            { text: 'Zeek Schedule Id', value: 'zeek_schedule_id', sortable: false, filterable: true },
            { text: 'Service Area', value: 'zeek_roster_schedule.service_area_code', sortable: false, filterable: true },
            { text: 'Region', value: 'zeek_roster_schedule.region', sortable: false, filterable: true },
            { text: 'Slot', value: 'slots', sortable: false, filterable: true },
        ],
        importItem: {
            importFile : null,
            schedule_id : null,
            import_file_error : "",
            import_content : [],
            is_import_file_valid : false
        },
        headers: [
                    { text: 'ID', value: 'id', sortable: false, filterable: false },
                    { text: 'Name', value: 'name', sortable: false, filterable: true },
                    { text: 'Payment Scheme', value: 'fulltime_payment_scheme.name', sortable: false, filterable: true },
                    { text: 'Start Date', value: 'period_start_date' },
                    { text: 'End Date', value: 'period_end_date' },
                    { text: 'Paid Date', value: 'paid_date' },
                    { text: 'Zeek Schedule', value: 'zeek_schedule', align:"center" },
                    { text: 'Is Processed', value: 'is_processed' },
                    { text: 'Adjustment', value: 'payment_adjustments', sortable: false, filterable: false, align:"center" },
                    { text: 'Detail', value: 'details', sortable: false, filterable: false, align:"center"},
                    { text: 'Delete', value: 'actions', sortable: false, filterable: false, },
                    { text: 'Edit', value: 'action_update', sortable: false, filterable: false, },
                    { text: 'Pay', value: 'action_pay', sortable: false, filterable: false, },

            ],
        detail_headers: [
                    { text: 'Employee No', value: 'employee_no' },
                    { text: 'Name', value: 'name_th' },
                    { text: 'Phone', value: 'phone' },
                    { text: 'Amount', value: 'amount' },
                    { text: '', value: 'data-table-expand' },
            ],
        menu: {
            start_date: false,
            end_date: false,
            paid_date: false
        },
        editMenu: {
            start_date: false,
            end_date: false,
            paid_date: false
        },
        editItem: {
            id : null,
            start_date: null,
            end_date: null,
            paid_date: null
        },
        newItem: {
            name: null,
            start_date: null,
            end_date: null,
            paid_date: null
        },
        validate_rules : {
            required: v => !!v || 'This field is required',
            filesize:  v => !v || v.size < 20*1024*1024 || 'Updload size should be less than 20 MB!',
            filetype:  v => {
                if(v){
                var ext = v.name.split('.').pop();
                return ["xlsx"].includes(ext) || 'Please upload .xlsx file only';
                } else {
                return 'This field is required'
                }
            }
        },
    }),
    components: {
        pageHeading
    },
    methods: {
         ...mapActions(['genCan', 'addPaymentSchedule', 'getPaymentSchedule', 'deletePaymentSchedule', 'paidPaymentSchedule', 'updatePaymentSchedule', 'getPaymentScheduleDetail', 'getPaymentScheme', 'getAvailableZeekRoster', 'addZeekRosterPayment', 'deleteZeekRosterPayment', 'exportPayment', 'exportFullPayment', 'deleteAllPaymentAdjustment', 'checkImportPaymentAdjustmentFile', 'ImportPaymentAdjustment']),
        async save() {
            if(this.$refs.form.validate())
            {
                let result = await this.addPaymentSchedule(this.newItem);
                if (result.is_complete)
                {
                    this.close();
                    this.reloadData();
                }
                else
                {
                    alert(result.message);
                }
            }
        },
        async clickLoadPaymentForm(record) {
            let result = await this.exportPayment(record.id);
            if (result)
            {
                alert("The report will be send to your email.");
            }
            else
            {
                alert("Something went wrong.");
            }
        },
        
        async showFulltimePaymentReport(schedule) {
            this.selSchedule = schedule;
            let result = await this.getPaymentScheduleDetail(schedule);
            this.payment_records = result.fulltime_payment_record;
            this.showReportDialog = true;
        },

        close () {
            this.dialog = false
            setTimeout(() => {
                this.newItem = {
                    name: null,
                    start_date: null,
                    end_date: null,
                    paid_date: null
                }
                this.$refs.form.resetValidation() // prevent validate error show again when click add
            }, 300)
        },
        editClose () {
            this.dialog_edit = false
            setTimeout(() => {
                this.editItem = {
                    id:null,
                    name: null,
                    start_date: null,
                    end_date: null,
                    paid_date: null
                }
                this.$refs.form.resetValidation() // prevent validate error show again when click add
            }, 300)
        },
        async loadPaymentScheme() {
            this.paymentScheme = await this.getPaymentScheme();
        },
        async reloadData() {
            this.schedules = await this.getPaymentSchedule();
        },
        async deleteItem(item) {
            if (confirm("Confirm to delete schedule paid on " + item.paid_date + " with id " + item.id + " ?"))
            {
                let result = await this.deletePaymentSchedule(item);
                if (result.is_complete)
                    await this.reloadData();
                else
                    alert(result.message);
            }
        },
        async paidItem(item) {
            if (confirm("Confirm to pay schedule paid on " + item.paid_date + " with id " + item.id + " ?"))
            {
                let result = await this.paidPaymentSchedule(item);
                if (result.is_complete)
                {
                    alert('Schedule to pay complete, please with 5 minutes for status changing');
                    await this.reloadData();
                }
                else
                    alert(result.message);
            }
        },
        async showEditItemModal(item) {

            this.editItem.id = item.id;
            this.editItem.start_date = item.period_start_date;
            this.editItem.end_date = item.period_end_date;
            this.editItem.paid_date = item.paid_date;

            this.dialog_edit = true;
        },
        async showManageZeekRoster(item) {
            console.log("showManageZeekRoster CALLED");
            this.selSchedule = item;
            this.dialog_zeek_schedule = true;
            this.search_available_schedule = "";
            this.search_attached_schedule = "";
            this.is_select_all_attached = false;
            this.is_select_all_available = false;


            //load available slot
            this.available_zeek_schedules = await this.getAvailableZeekRoster({ fulltime_payment_id : item.id });
            for (let i = 0 ; i < this.available_zeek_schedules.length ; i++)
                this.available_zeek_schedules[i].is_sel = false;

            this.attached_zeek_schedules = Object.values(lodash.reduce(item.zeek_roster_payment, (result, payment) => {
                if (!result[payment.zeek_schedule_id])
                {
                    result[payment.zeek_schedule_id] = payment;
                    result[payment.zeek_schedule_id]["slots"] = [];
                }
                result[payment.zeek_schedule_id]["slots"].push(payment.zeek_roster_schedule_date);
                return result;
            }, { } ));
            for (let i = 0 ; i < this.attached_zeek_schedules.length ; i++)
                this.attached_zeek_schedules[i].is_sel = false;
        },
        async updateItem() {
            let result = await this.updatePaymentSchedule(this.editItem);
            if (result.is_complete)
            {
                alert('Update Complete');
                await this.reloadData();
                this.dialog_edit = false;
            }
            else
                alert(result.message);
        },

        async addZeekPayment(zeek_schedule_id, slots) {
            try
            {
                let result = await this.addZeekRosterPayment({
                    fulltime_payment_id : this.selSchedule.id,
                    payload : {
                        zeek_schedule_id : zeek_schedule_id,
                        slots : slots
                    }
                });
                await this.reloadManageZeek(this.selSchedule.id);
            }
            catch(e)
            {
                alert("Something went wrong");
                console.log(e);
            }
        },

        async removeZeekPayment(zeek_schedule_id, slots) {
            try
            {
                let result = await this.deleteZeekRosterPayment({
                    fulltime_payment_id : this.selSchedule.id,
                    payload : {
                        zeek_schedule_id : zeek_schedule_id,
                        slots : slots
                    }
                });
                await this.reloadManageZeek(this.selSchedule.id);
            }
            catch(e)
            {
                alert("Something went wrong");
                console.log(e);
            }
        },

        async reloadManageZeek(fulltime_payment_id) {
            this.schedules = await this.getPaymentSchedule();
            this.selSchedule = lodash.filter(this.schedules, { id : fulltime_payment_id })[0];

            this.available_zeek_schedules = await this.getAvailableZeekRoster({ fulltime_payment_id : fulltime_payment_id });
            for (let i = 0 ; i < this.available_zeek_schedules.length ; i++)
                this.available_zeek_schedules[i].is_sel = false;

            this.attached_zeek_schedules = Object.values(lodash.reduce(this.selSchedule.zeek_roster_payment, (result, payment) => {
                if (!result[payment.zeek_schedule_id])
                {
                    result[payment.zeek_schedule_id] = payment;
                    result[payment.zeek_schedule_id]["slots"] = [];
                }
                result[payment.zeek_schedule_id]["slots"].push(payment.zeek_roster_schedule_date);
                return result;
            }, { } ));
            for (let i = 0 ; i < this.attached_zeek_schedules.length ; i++)
                this.attached_zeek_schedules[i].is_sel = false;
            this.countAvailableSel();
            this.countAttachedSel();
        },

        async clickExportPreviewPayment(record) {
            let result = await this.exportFullPayment(record.id);
            if (result)
            {
                alert("The report will be send to your email.");
            }
            else
            {
                alert("Something went wrong.");
            }
        },
        async clickImportAdjustment(record) {
            this.selSchedule = record;
            this.importItem.schedule_id = record.id;
            this.showImportPaymentAdjustmentDialog = true;
        },
        async performImportPaymentAdjustment() {
            let result = await this.ImportPaymentAdjustment(this.importItem)
            if (result.is_complete)
            {
                alert("Import complete");
                this.dialog_import = false;
            }
            else
            {
                alert("Error while import file");
                this.importItem.import_file_error = result.message;
            }
        },
        async checkImportFile() {
            this.importItem.import_file_error = "";
            this.importItem.import_content = [];
            this.importItem.is_import_file_valid = false;

            let importCheckResult = await this.checkImportPaymentAdjustmentFile(this.importItem);
            console.log(importCheckResult);
            this.importItem.is_import_file_valid = importCheckResult.is_complete;
            this.importItem.import_file_error = importCheckResult.message;
            if (importCheckResult.is_complete)
                this.importItem.import_content = importCheckResult.import_content;
        },

        async clickClearAdjustmentAll(record) {
            if (confirm("Confirm to clear all adjustment on this payment schedule?"))
            {
                let result = await this.deleteAllPaymentAdjustment(record.id);
                if (result)
                {
                    alert("Delete complete");
                }
                else
                {
                    alert("Something went wrong.");
                }
            }
        },
        paginateAvailableZeekSchedule(paginate) {
            console.log(paginate);
            this.per_page_available_zeek_schedules = paginate.itemsPerPage;
            this.page_available_zeek_schedules = paginate.page;


        },
        paginateAttachedZeekSchedule(paginate) {
            console.log(paginate);
            this.per_page_attached_zeek_schedules = paginate.itemsPerPage;
            this.page_attached_zeek_schedules = paginate.page;


        },
        computeAvailableSelectAllStatus(items) {
            console.log(items)
            let is_sel_cnt = 0;
            for (let i = 0 ; i < items.length ; i++)
            {
                if (items[i].is_sel)
                    is_sel_cnt++;
            }

            if (is_sel_cnt == items.length && items.length > 0 )
                this.is_select_all_available = true;
            else if (is_sel_cnt > 0)
                this.is_select_all_available = null;
            else
                this.is_select_all_available = false;
        },
        computeAttachedSelectAllStatus(items) {
            console.log(items)
            let is_sel_cnt = 0;
            for (let i = 0 ; i < items.length ; i++)
            {
                if (items[i].is_sel)
                    is_sel_cnt++;
            }

            if (is_sel_cnt == items.length && items.length > 0 )
                this.is_select_all_attached = true;
            else if (is_sel_cnt > 0)
                this.is_select_all_attached = null;
            else
                this.is_select_all_attached = false;
        },
        countAvailableSel() {
            let is_sel_cnt = 0;
            for (let i = 0 ; i < this.available_zeek_schedules.length ; i++)
            {
                if (this.available_zeek_schedules[i].is_sel)
                    is_sel_cnt++;
            }
            this.cnt_sel_available_zeek_schedules = is_sel_cnt;
            console.log("this.cnt_sel_available_zeek_schedules", this.cnt_sel_available_zeek_schedules)
        },
        countAttachedSel() {
            let is_sel_cnt = 0;
            for (let i = 0 ; i < this.attached_zeek_schedules.length ; i++)
            {
                if (this.attached_zeek_schedules[i].is_sel)
                    is_sel_cnt++;
            }
            this.cnt_sel_attached_zeek_schedules = is_sel_cnt;
            console.log("this.cnt_sel_attached_zeek_schedules", this.cnt_sel_attached_zeek_schedules)
        },
        async addAllZeekPayment() {

            let add_schedule = lodash.reduce(this.available_zeek_schedules, (res, schedule) => {
                if (schedule.zeek_schedule_id.toString().includes(this.search_available_schedule) || schedule.region.includes(this.search_available_schedule) || schedule.service_area_code.includes(this.search_available_schedule) )
                {
                    if (schedule.is_sel)
                        res.push(schedule);
                }
                return res;
            }, [])

            this.is_select_all_available = false;

            console.log("add_schedule", add_schedule);

            for (let i = 0 ; i < add_schedule.length ; i++)
            {
                this.addZeekPayment(add_schedule[i].zeek_schedule_id, add_schedule[i].free_slot)
            }
            
        },
        async removeAllZeekPayment() {
            let remove_schedule = lodash.reduce(this.attached_zeek_schedules, (res, schedule) => {
                if (schedule.zeek_schedule_id.toString().includes(this.search_attached_schedule) || schedule.zeek_roster_schedule.region.includes(this.search_attached_schedule) || schedule.zeek_roster_schedule.service_area_code.includes(this.search_attached_schedule) )
                {
                    if (schedule.is_sel)
                        res.push(schedule);
                }
                return res;
            }, [])

            this.is_select_all_attached = false;
            console.log("remove_schedule", remove_schedule);

            for (let i = 0 ; i < remove_schedule.length ; i++)
            {
                this.removeZeekPayment(remove_schedule[i].zeek_schedule_id, remove_schedule[i].slots)
            }
        },
        changeSelectAllAvailable() {
            console.log("is_select_all_available", this.is_select_all_available);
            console.log({
                per_page_available_zeek_schedules : this.per_page_available_zeek_schedules, 
                page_available_zeek_schedules :  this.page_available_zeek_schedules
            });
            let localpage = this.page_available_zeek_schedules;
            let st_idx = this.per_page_available_zeek_schedules * (this.page_available_zeek_schedules-1);
            let en_idx = (this.per_page_available_zeek_schedules * (this.page_available_zeek_schedules)) - 1;
            
            let idx = 0;

            for (let i = 0 ; i < this.available_zeek_schedules.length ; i++)
            {
                let schedule = this.available_zeek_schedules[i];
                if (schedule.zeek_schedule_id.toString().includes(this.search_attached_schedule) || schedule.region.includes(this.search_attached_schedule) || schedule.service_area_code.includes(this.search_attached_schedule) )
                {
                    if (st_idx <= idx && idx <= en_idx)
                        this.available_zeek_schedules[i].is_sel = this.is_select_all_available;
                    //this.available_zeek_schedules.splice(i, 1, this.available_zeek_schedules[i]);

                    idx++;
                }

            }

            let a = lodash.clone(this.available_zeek_schedules);
            this.available_zeek_schedules = [];
            
            window.setTimeout(() => {  this.available_zeek_schedules = a; this.page_available_zeek_schedules = localpage; this.countAvailableSel(); }, 100);

        },
        changeSelectAllAttached() {
            console.log("is_select_all_attached", this.is_select_all_attached);
            console.log({
                per_page_attached_zeek_schedules : this.per_page_attached_zeek_schedules, 
                page_attached_zeek_schedules :  this.page_attached_zeek_schedules
            });
            let localpage = this.page_attached_zeek_schedules;
            let st_idx = this.per_page_attached_zeek_schedules * (this.page_attached_zeek_schedules-1);
            let en_idx = (this.per_page_attached_zeek_schedules * (this.page_attached_zeek_schedules)) - 1;
            
            let idx = 0;


            for (let i = 0 ; i < this.attached_zeek_schedules.length ; i++)
            {
                let schedule = this.attached_zeek_schedules[i];
                if (schedule.zeek_schedule_id.toString().includes(this.search_attached_schedule) || schedule.zeek_roster_schedule.region.includes(this.search_attached_schedule) || schedule.zeek_roster_schedule.service_area_code.includes(this.search_attached_schedule) )
                {
                    if (st_idx <= idx && idx <= en_idx)
                    this.attached_zeek_schedules[i].is_sel = this.is_select_all_attached;
                        //this.attached_zeek_schedules.splice(i, 1, this.attached_zeek_schedules[i] );
                    idx++;
                }
            }

            let a = lodash.clone(this.attached_zeek_schedules);
            this.attached_zeek_schedules = [];

            window.setTimeout(() => { this.attached_zeek_schedules = a; this.page_attached_zeek_schedules = localpage; this.countAttachedSel(); }, 100);
            
        },
        changeSelectAllAvailableToNull() {
            this.is_select_all_available = null;
            this.countAvailableSel();
        },
        changeSelectAllAttachedToNull() {
            this.is_select_all_attached = null;
            this.countAttachedSel();
        }
    },
    async beforeMount() {
        
        await this.reloadData();
        await this.loadPaymentScheme();
        this.genCan();
    },
    computed: {
        ...mapGetters(['canDo','allCategories'])
    },
    mounted() {
        console.log(this.canDo);
    }
};
</script>